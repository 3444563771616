<template>
	<div class="menu">
		<!-- <template>
  			<el-alert title="若想继续新增服务器，请点击按钮新增" type="warning" :closable="false">
				  <el-button type="danger" style="display:inline-block" @click="handleBuy()">立即新增</el-button>
			</el-alert>
		</template> -->
		<div class="box">
			<div class="leftcontent">
			<div class="terms">
				<div class="item item1">
					<router-link to="/Distribution/distribution">
						<p class="title"><span>总收益</span><span class="detail"><span>详情</span><i class="el-icon-arrow-right"></i></span></p>
					</router-link>
					<template>
						<p class="number">{{walletData.balance + walletData.freeze}}</p>
					</template>
				</div>
				<div class="item item3">
					<p class="title"><span>可提现</span></p>
					<p class="filecoin">信息来源：非小号</p>
					<p class="number">{{walletData.balance}}</p>
				</div>
				<div class="item item4">
					<router-link to="/operating">
						<p class="title"><span>冻结中</span></p>
					</router-link>
					
					<p class="number">{{walletData.freeze}}</p>
				</div>
			</div>
			<!-- <div class="terms terms_common">
				<div class="item item1">
					<router-link to="/asset/asset">
						<p class="title"><span>通证数量</span><span class="detail"><span>详情</span><i class="el-icon-arrow-right"></i></span></p>
					</router-link>
					
					<template>
						<p class="number">{{form.coins.total}}</p>
					</template>
				</div>
				<div class="item item3">
					<p class="title"><span>实时价格</span></p>
					<p class="filecoin">信息来源：非小号</p>
					<p class="number">{{form.realtime_price}}</p>
				</div>
			</div> -->
			<div class="account-box">
				<div class="account">
					<p class="main-title">我的数字通证</p>
					<div class="account-detail">
						<div class="available-balance">
							<p class="title"><i class="el-icon-bank-card"></i>ALEO</p>
							<p class="number">0</p>
						</div>
						<div class="available-balance">
							<p class="title"><i class="el-icon-bank-card"></i>IO</p>
							<p class="number">0</p>
						</div>
						<div class="contribution">
							<p class="title"><i class="el-icon-trophy"></i>证明测试奖励</p>
							<p class="number">{{info.t32}}</p>
						</div>
					</div>
				</div>
				<div class="my">
					<div class="hashrate" @click="goDistribution()">
						<p class="title"><i class="el-icon-tickets"></i>个人PPS</p>
						<p class="number">{{info.selfCount}}</p>
						<!-- <el-button size="mini" @click="handleBuy()">新增<i class="el-icon-arrow-right"></i></el-button> -->
					</div>
					<div class="teamNum">
						<div class="serve-number left" @click="goPPSteam()">
							<p class="title"><i class="el-icon-coordinate"></i>直接销售</p>
							<div class="bottom">
								<p class="number">{{info.teamCount}}</p>
							</div>
						</div>
						<div class="serve-number"  v-if="this.$store.state.base.userId == 33">
							<p class="title"><i class="el-icon-house"></i>团队PPS</p>
							<div class="bottom">
								<p class="number">{{info.teamPps}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="ecology-box" v-if="judgemarket">
				<div class="title">
					<div class="font">生态板块</div>
					<el-button type="primary" size="mini" plain >详情<i class="el-icon-arrow-right el-icon--right"></i></el-button>
				</div>
				<div class="content">
					<div class="item">
						<div class="top">质押总数</div>
						<div class="bottom">{{Coinsdata.total_meals}}</div>
					</div>
					<div class="item">
						<div class="top">生态产出总数</div>
						<div class="bottom">{{Coinsdata.total}}</div>
					</div>
					<div class="item">
						<div class="top">实际可提现</div>
						<div class="bottom">
							<div class="box">
								<div style="width: 100%;">{{Coinsdata.cash_int}}</div>
								<div class="total_cash" >(总可提现数量:{{Coinsdata.cash}})</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="top">总锁仓</div>
						<div class="bottom">{{Coinsdata.locked}}</div>
					</div>
				</div>
			</div> -->
			<!-- <div class="assets">
				<p class="title">我的通证</p>
				<div class="type">
					<div class="item">
						<div class="top">
							<img src="../../assets/img/ic_jianglishouyi.png" alt="">
							<div class="content">
								<p class="title">通证数量</p>
							</div>
						</div>
						<div class="bottom">
							<span>{{form.coins.total}}</span>
						</div>
						<div class="check-details">
							<router-link to="/asset/asset">
								<el-button type="primary" size="mini" plain >详情<i class="el-icon-arrow-right el-icon--right"></i></el-button>
							</router-link>
						</div>
					</div>
					<div class="item">
						<div class="top">
							<img src="../../assets/img/ic_diyasuocnag.png" alt="">
							<div class="content">
								<p class="title">实际可提现数量</p>
							</div>
						</div>
						<div class="bottom">
							<div class="box">
								<div style="width: 100%;">{{form.coins.cash_int}}</div>
								<div class="total_cash" >(总可提现数量:{{form.coins.cash}})</div>
							</div>
							
						</div>
						
						<div class="check-details">
							<router-link to="/asset/asset">
								<el-button type="primary" size="mini" plain >详情<i class="el-icon-arrow-right el-icon--right"></i></el-button>
							</router-link>
						</div>
					</div>
					<div class="item">
						<div class="top">
							<img src="../../assets/img/ic_hehuoren.png" alt="">
							<div class="content">
								<p class="title">锁仓数</p>
							</div>
						</div>
						<div class="bottom">
							{{form.coins.locked}}
						</div>
						<div class="check-details">
							<router-link to="/asset/asset">
								<el-button type="primary" size="mini" plain >详情<i class="el-icon-arrow-right el-icon--right"></i></el-button>
							</router-link>
						</div>
					</div>
				</div>
			</div> -->
			<div class="current-time">
				<p class="title">系统时间</p>
				<div class="date">
					<div class="left">
						<span style="font-size:80px;color:#333333;">{{nowTime}}</span>
						<span style="font-size:26px;color:#333333;">{{nowSec}}</span>
					</div>
					<div class="right">
						<p style="font-size:20px;color:#333333;padding-bottom:5px;">{{nowWeek}}</p>
						<p style="font-size:20px;color:#333333;padding-top:5px;">{{nowDate}}</p>
					</div>
				</div>
			</div>
			</div>
			<div class="rightcontent">
				<div class="focus-on">
					<img src="../../assets/img/wechat.png" alt="">
					<div class="content">
						<p class="main">关注智岚博丰公众号</p>
						<p class="dec">与您相遇，无限可能</p>
					</div>
				</div>
				<div class="service">
					<p class="title">客服中心</p>
					<div class="phone"><i class="el-icon-service"></i>0536-8880726</div>
					<el-button>有问题，请电话咨询</el-button>
				</div>
			</div>
		</div>
		
	</div>	
</template>

<script>
import { Personal,Distribution} from "@/api/index";
import countdown from "@/components/countdown";
  export default {
    data() {
      return {
		form: {coins:{}},
		nowWeek: '',
    	nowDate: '',
		nowTime: '',
		nowSec:'',
		judgemarket:false,
		walletData:{},
		info:{}
	  };
	},
	components:{
    	countdown
	},
	created() {
		this.walletInfo();
		// this.Total()
		// this.Coins()
		this.Info()
		},
	 mounted() {
		this.timer = setInterval(() => {
    	this.setNowTimes()
  		}, 1000)
  },
  methods: {
	  setNowTimes () {
  		let myDate = new Date()
  		let wk = myDate.getDay()
  		let yy = String(myDate.getFullYear())
  		let mm = myDate.getMonth() + 1
  		let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  		let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  		let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  		let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  		let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  		let week = weeks[wk]
  		this.nowDate = yy + '年' + mm + '月' + dd + '日'
		this.nowTime = hou + ':' + min 
		this.nowSec = sec
  		this.nowWeek = week
		},
	   async Info() {
		  let { status, data } = await Personal.info();
		  if (status) {
		  	if(data.code == 0) {
		  		this.info = data.data;
		  		if(this.info.truename!='') {
		  			this.username = this.info.truename
		  		}else {
		  			this.username = this.info.passport
		  		}
		  	}
		  	if(typeof(data.data.truename) == 'undefined' || data.data.truename== '') {
		  		this.username = data.data.passport
		  	}else {
		  		this.username = data.data.truename
		  	}
		  	this.$store.commit('setBase',{
		  		username:this.username.username,
		  		passport:data.data.passport,
		  		userId:data.data.userId,
		  		userName:this.username,
		  		auditStatus:data.data.auditStatus,
		  		phone:data.data.phone
		  	})
		  }
	  },
	  //钱包信息
	  async walletInfo() {
	  	let { status, data } = await Distribution.wallet();
	  	if (status) {
	  		if(data.code == 0) {
	  			this.walletData = data.data;
	  		}
	  	}
	  },
	  goDistribution() {
	  	this.$router.push('/distribution/distribution')
	  },
	  goPPSteam() {
	  	this.$router.push('/distribution/ppsteam')
	  }
},
  };
</script>

<style lang="less">
	.menu {
		body {
				margin: 0;
				padding: 0;
				background-color: #F0F3FA;
			}
			.el-pagination {
			    float: right;
			    margin-right: 67px;
			}
			.el-scrollbar__wrap {
		  overflow-x: hidden!important;
			}
			.el-alert {
				display: flex;
				align-items: center;
				height: 60px;
			}
			.el-alert:after {
				content: '';
				min-height: inherit;
				font-size: 0;
			}
			.el-alert__content {
		  	display: flex;
				align-items: center;
				color: #333333;
				.el-alert__title.is-bold {
		  		font-weight: 500;
				}
			}
			.el-button--danger {
				margin-left: 80px;
			}
			.box {
				display: flex;
			}
			.leftcontent {
				width: 81%;
				padding: 20px;
				.el-alert--info.is-light {
					background-color: #ffffff;
					height: 60px;
				}
				.el-alert .el-alert__description  {
					margin: 0;
					position: absolute;
		  		right: 40px;
					bottom: 23px\9;
				}
				.el-dropdown-link {
		  		cursor: pointer;
					color: #409EFF;
					margin-left: 30px;
		 		 }
				.el-icon-arrow-down {
		 			font-size: 12px;
				  }
				.terms {
					width: 100%;
					height: 180px;
					display: flex;
					margin-top: 20px;
					.item {
						width: 33%;
						margin-right: 1%;
						border-radius: 10px;
						p {
							padding: 0 20px;
						}
						.title {
							display: flex;
							align-items: center;
							justify-content: space-between;
							color: #ffffff;
							margin: 20px 0;
							font-size: 24px;
							.detail {
								font-size: 20px;
								float: right\9;
								margin-bottom: 10px\9;
								span {
									vertical-align: middle;
								}
								.el-icon-arrow-right {
									vertical-align: middle;
									font-size: 16px;
								}
							}
						}
						.filecoin {
							font-size: 16px;
							color: #ffffff;
						}
						.number {
							font-size: 30px;
							color: #ffffff;
							margin-top: 11px;
		  				margin-bottom: 20px;
						}
					}
					.item1 {
						background: url(../../assets/img/img_chibishuliang.png) no-repeat center center;
						background-size: cover;
					}
					.item2 {
						background: url(../../assets/img/img_suocnagwakuang.png) no-repeat center center;
						background-size: cover;
					}
					.item3 {
						background: url(../../assets/img/ic_shishijiage.png) no-repeat center center;
						background-size: cover;
					}
					.item4 {
						background: url(../../assets/img/ic_yunying.png) no-repeat center center;
						background-size: cover;
					}
					.item:last-child {
						margin-right: 0;
					}
				}
				.terms_common {
					.item {
						width: 50%;
					}
				}
				.account-box {
					display: flex;
					width: 100%;
					height: 254px;
					margin-top: 20px;
					.account {
						width: 67%;
						margin-right: 1%;
						background-color: #ffffff;
						border-radius: 10px;
						p {
							margin: 20px 0;
							padding: 0 30px;
							font-size: 24px;
							color: #333333;
							span {
								font-size: 16px;
								color: #999999;
								margin-left: 20px;
							}
						}
						.account-detail {
							display: flex;
							.available-balance {
								width: 50%;
								height: 106px;
								
								.title {
									text-align: center;
									margin-top: 10px;
									margin-bottom: 0;
									font-size: 16px;
									color: #333333;
									i {
										margin-right: 10px;
									}
								}
								.number {
									text-align: center;
									margin-bottom: 0;
									margin-top: 25px;
									font-size: 36px;
									color: #333333;
								}
							}
							.contribution {
								width: 50%;
								height: 106px;
								border-left: 1px dashed #BBBBBB;
								.title {
									text-align: center;
									margin-top: 10px;
									margin-bottom: 0;
									font-size: 16px;
									color: #333333;
									i {
										margin-right: 10px;
									}
								}
								.number {
									text-align: center;
									margin-top: 25px;
									margin-bottom: 0;
									font-size: 36px;
									color: #333333;
								}
							}
						}
					}
					.my {
						width: 33%;
						border-radius: 10px;
						.hashrate {
							cursor: pointer;
							height: 117px;
							border-radius: 10px;
							margin-bottom: 20px;
							background-color: #ffffff;
							position: relative;
							.title {
								margin: 0;
								padding: 20px;
								font-size: 16px;
								color: #333333;
								i {
										margin-right: 10px;
									}
							}
							.number {
								margin: 0;
								padding: 0 20px 0px 20px;
								font-size: 34px;
								color: #333333;
							}
							.el-button--mini {
								color: #fff;
								background-color: #FF5722;
								border-color: #FF5722;
								padding: 10px 22px 10px 27px;
								position: absolute;
								right: 20px;
								top: 16px;
								.el-icon-arrow-right {
									padding-left: 5px;
								}
							}
						}
						.teamNum  {
							display: flex;
							align-items: center;
							.left {
								margin-right: 2%;
							}
							.serve-number {
								cursor: pointer;
								height: 117px;
								width: 49%;
								border-radius: 10px;
								background-color: #ffffff;
								.title {
									margin: 0;
									padding: 20px;
									font-size: 16px;
									color: #333333;
									i {
										margin-right: 10px;
									}
								}
								.bottom {
									display: flex;
									align-items: center;
									position: relative;
									.number {
									margin: 0;
									padding: 0 20px 0px 20px;
									font-size: 34px;
									color: #333333;
									}
								}
							}
						}
					}
				}
				.ecology-box {
					width: 100%;
					height: 330px;
					border-radius: 10px;
					background-color: #ffffff;
					margin-top: 33px;
					.title {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 20px;
						.font {
							font-size: 24px;
							color: #333333;
						}
					}
					.content {
						display: flex;
						padding-left: 20px;
						padding-right: 20px;
						margin-top: 20px;
						.item {
							width: calc((100% - 240px) / 4);
							height: 144px;
							border-right: 1px dashed #999999;
							padding: 0 30px;
							.top {
								font-size: 20px;
								color: #333333;
							}
							.bottom {
								background-color: #F9FAFC;
								height: 92px;
								margin: 20px 0 0 0;
								line-height: 92px;
								text-align: center;
								font-size: 30px;
								color: #333333;
								border-radius: 10px;
								position: relative;
								.box {
									position: relative;
									.total_cash {
										position: absolute;
										bottom: 0;
										font-size: 14px;
										width: 100%;
										line-height: 20px;
									}
								}
								
							}
						}
						.item:last-child {
							border-right: 0;
						}
					}
				}
				.assets {
					width: 100%;
					height: 330px;
					border-radius: 10px;
					background-color: #ffffff;
					margin-top: 20px;
					.title {
						padding: 20px;
						font-size: 24px;
						color: #333333;
					}
					.type {
						display: flex;
						.item {
							width: 32.7%;
							.top {
								display: flex;
								position: relative;
								img {
									vertical-align: middle;
									padding: 0 0 0 20px;
								}
								.content {
									vertical-align: middle;
									.title {
										font-size: 18px;
										color: #333333;
										padding: 0 0 0 10px;
									}
									.date {
										font-size: 16px;
										color: #999999;
										padding: 5px 0 0 10px;
									}
									
								}
								
							}
							.bottom {
								background-color: #F9FAFC;
								height: 92px;
								margin: 20px 0 0 20px;
								line-height: 92px;
								text-align: center;
								font-size: 30px;
								color: #333333;
								border-radius: 10px;
								position: relative;
								.box {
									position: relative;
									.total_cash {
										position: absolute;
										bottom: 0;
										font-size: 14px;
										width: 100%;
										line-height: 20px;
									}
								}
								
							}
							.check-details {
								margin: 20px;
							}
						}
					}
				}
				.current-time {
					height: 250px;
					width: 100%;
					border-radius: 10px;
					background-color: #ffffff;
					margin-top: 20px;
					.title {
						padding: 20px;
						font-size: 24px;
						color: #333333;
					}
					.date {
						background-color: #F9FAFC;
						height: 142px;
						width: 90%;
						margin: 0 auto;
						border-radius: 10px;
						display: flex;
						align-items: center;
						.left {
							padding: 15px 120px 15px 86px;
						}
					}
				}
				
			}
			.rightcontent {
				position: fixed;
				right: 0;
				width: 220px;
				margin-right: 35px;
				margin-top: 20px;
				height: 471px;
				background-color: #ffffff;
				padding: 20px;
				border-radius: 10px;
				.focus-on {
					text-align: center;
					padding-bottom: 20px;
					border-bottom: 2px solid #EEEEEE;
					img {
						
						width: 212px;
						height: 212px;
					}
					.content {
						text-align:left;
						margin-top: 10px;
						.main {
							font-size: 16px;
							color: #333333;
						}
						.dec {
							font-size: 14px;
							color: #999999;
							padding-top: 6px;
						}
					}
				}
				.service {
					padding-bottom: 30px;
					.title {
						font-size: 16px;
						color: #333333;
						padding: 20px 0;
					}
					.phone {
						font-size: 22px;
						color: #333333;
						i {
							color: #1493FA;
							margin-right: 20px;
						}
					}
					.el-button {
						width: 100%;
						border-color: #1493FA;
						color: #1493FA;
						margin-top: 20px;
					}	
				}	
			}
			.el-button--primary {
				margin-top: 0;
			}
			@media screen and (max-width:1878px ) {
			 .rightcontent {
				display: none;
			 }
			 .leftcontent {
				 width: 100%;
			 }
			}
	}
	
</style>